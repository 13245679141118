<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="titleContainer bg-yellow">
          <p class="titleText">{{ $t("labels.todayIFeel") }}</p>
        </div>
        <div class="subContainer">
          <div class="emoticonsGroup" v-if="getEmoticons().length > 0">
            <Carousel
              :items-to-show="5"
              :wrap-around="true"
              :items-to-scroll="5"
              :breakpoints="breakpoints"
              :mouse-drag="false"
            >
              <Slide v-for="(item, index) in getEmoticons()" :key="index">
                <div class="slideContainer" style="width: 100%">
                  <div
                    class="emoticonImageContainer"
                    @click="selectEmotion(item)"
                  >
                    <img
                      class="emoticonImage"
                      :src="`data:image/png;base64,${item.icon}`"
                    />
                  </div>
                  <p class="emoticonText">{{ item.text }}</p>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
          <div class="expandBtn" @click="toggleExpand()">
            <font-awesome-icon v-if="isExpanded" icon="minus" />
            <font-awesome-icon v-else icon="plus" />
          </div>
          <div
            v-if="weeklyChallenge && weeklyChallenge.header"
            class="titleContainer bg-primary"
          >
            <p class="titleText">
              {{ $t("labels.challengeOfTheWeek") }}
            </p>
          </div>
          <div
            class="subContainer p-0 btn flex-1"
            @click="goToWeeklyChallenge()"
            v-if="weeklyChallenge && weeklyChallenge.header"
          >
            <div class="rowContainer border flex-1">
              <p>{{ weeklyChallenge.header }}</p>
            </div>
            <div class="weeklyChallengeContainer flex-1">
              <img :src="pinkEyeImage" class="pinkEye" />
              <div class="weeklyChallengeContent">
                <p class="secondaryTitleText">{{ $t("labels.topic") }}:</p>
                <p class="secondaryTitleText">{{ weeklyChallenge.title }}</p>
              </div>
            </div>
          </div>
          <div
            class="titleContainer bg-blue mt-50"
            v-if="getPopVideos().length > 0"
          >
            <p class="titleText">{{ $t("labels.popularVideos") }}</p>
          </div>
          <div class="videosContainer" v-if="getPopVideos().length > 0">
            <Carousel :items-to-show="2.5" :wrap-around="true">
              <Slide v-for="(item, index) in getPopVideos()" :key="index">
                <div class="slideContainer">
                  <div class="videoImageContainer">
                    <img
                      class="videoImage"
                      :src="`data:image/png;base64,${item.image}`"
                    />
                    <img
                      class="videoIcon btn"
                      :src="videoIcon"
                      @click="openVideo(item.url)"
                    />
                  </div>
                  <p class="videoTitle">{{ item.title }}</p>
                </div>
              </Slide>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal v-model="isShow" :click-out="true">
    <div class="mainContainer modalContainer">
      <div class="emoticonContainer">
        <div
          v-for="(item, index) in getEmoticons()"
          :key="index"
          class="emoticonItem btn"
          @click="selectEmotion(item)"
        >
          <div class="emoticonImageContainer">
            <img
              class="emoticonImage"
              :src="`data:image/png;base64,${item.icon}`"
            />
          </div>
          <p class="emoticonText">{{ item.text }}</p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import CustomHeader from "../components/CustomHeader.vue"
import { Carousel, Navigation, Slide } from "vue3-carousel"
import { Modal } from "vue-neat-modal"

import "vue-neat-modal/dist/vue-neat-modal.css"
import "vue3-carousel/dist/carousel.css"
import { mapState } from "vuex"
export default {
  name: "MyStoryPage",
  components: {
    CustomHeader,
    Carousel,
    Slide,
    Navigation,
    Modal,
  },
  data() {
    return {
      isShow: false,
      pinkEyeImage: require("../assets/image/svg/pinkEye.svg"),
      videoIcon: require("../assets/image/svg/videos.svg"),
      isExpanded: false,
      breakpoints: {
        // 700px and up
        300: {
          itemsToShow: 3,
          itemsToScroll: 3,
        },
        400: {
          itemsToShow: 5,
          itemsToScroll: 5,
        },
      },
    }
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/")
    },
    goToLogin() {
      this.$router.push("/login")
    },
    openVideo(url) {
      window.open(url, "_blank")
    },
    goToWeeklyChallenge() {
      this.$router.push("/weeklyChallenge")
    },
    toggleExpand() {
      //this.isExpanded = !this.isExpanded
      this.isShow = true
    },
    getEmoticons() {
      return this.emoticons
    },
    getPopVideos() {
      return this.popVideos
    },
    selectEmotion(item) {
      this.isShow = false;
      this.$toast.show(item.reply, { position: "bottom" })
      this.$store.dispatch("main/addEmotionAttempt", item.id)
    },
    closeModal() {},
  },
  computed: mapState({
    popVideos: (state) => state.main.popVideos,
    emoticons: (state) => state.main.emoticons,
    weeklyChallenge: (state) => state.main.weeklyChallenge,
  }),
  mounted() {
    this.$store.dispatch(
      "root/setLoading",
      {
        isLoading: this.emoticons.length === 0,
        label: "notifications.updating",
      },
      { root: true }
    )
    Promise.all([
      this.$store.dispatch("main/getEmoticons"),
      this.$store.dispatch("main/getWeeklyChallenge"),
      this.$store.dispatch("main/getPopVideos"),
    ]).finally(() => {
      this.$store.dispatch(
        "root/setLoading",
        { isLoading: false, label: "" },
        { root: true }
      )
    })
  },
}
</script>

<style scoped>
.emoticonsGroup {
  width: 100%;
}
.modalContainer {
  width: 100%;
  margin: auto;
  overflow: auto;
  max-height: 400px;
  padding: 18px 10px !important;
}
.emoticonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.singleRow {
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow: hidden;
}
.emoticonItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 3px 0px;
}
.emoticonImageContainer {
  width: 70px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  overflow: hidden;
  margin-bottom: 5px;
  cursor: pointer;
}
.emoticonImage {
  max-width: 70px;
  height: 100%;
}
.emoticonText {
  font-size: 12px;
  color: var(--textColor);
}
.expandBtn {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: lightgray;
  padding: 5px;
  margin-top: -10px;
  margin-right: -10px;
  box-shadow: 0 1px 4px 0 lightgray, 0 1px 4px 0 lightgray;
  cursor: pointer;
}
.expandBtn:hover {
  opacity: 0.8;
}
.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
.secondaryTitleText {
  font-size: 18px;
  color: var(--textColor);
  overflow-wrap: break-word;
  margin-left: 10px;
}

@media only screen and (max-width: 700px) {
  .secondaryTitleText {
    font-size: 15px;
  }
}
.weeklyChallengeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
}
.weeklyChallengeContent {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-right: 10px;
}
.videoTitle {
  color: white;
  font-size: 10px;
  margin-top: 5px;
}
.slideContainer {
  display: flex;
  width: 90%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  overflow: hidden;
  border-radius: 10px;
}
.videoImageContainer {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background-color: black;
  border-radius: 8px;
  overflow: hidden;
}
.videoImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.videoIcon {
  position: absolute;
  height: 50px;
  width: 50px;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}
@media only screen and (max-width: 700px) {
  .videoIcon {
    height: 30px;
    width: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
  }
}
.pinkEye {
  width: 80px;
  height: 80px;
  margin-left: 10px;
}
.border {
  border-bottom: 1px solid lightgray;
}
.videosContainer {
  background-color: var(--blue);
  border-radius: 15px;
  padding: 10px;
  width: 100%;
}
</style>
<style>
.carousel__prev,
.carousel__next {
  background-color: transparent !important;
  color: black !important;
  height: 62px;
  margin-top: -10px;
}
.vue-neat-modal {
  max-width: 500px !important; 
}
</style>
