<template>
  <div class="wp-100 align-center">
    <div class="headerContainer">
      <div class="badgeLogoContainer">
        <img class="badgeLogo" :src="badgeLogo" @click="goToDashBoard()" />
      </div>
      <div class="avatarContainer" @click="goToProfile()">
        <img class="avatar" :src="getAvatar()" />
      </div>
    </div>
    <div class="greetingsContainer">
      <p class="greetingsText">
        {{ `${$t("labels.hi")} ${getFirstName()}${"!"}` }}
      </p>
      <div class="emoticonsContainer">
        <p class="emoticonsTitle">{{ $t("labels.emotyPoints") }}</p>
        <p class="emoticonsCount">{{ getEmoticonsCount() }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { loadStore } from "../utils/configureStorage";

export default {
  name: "CustomInput",
  data() {
    return {
      badgeLogo: require("../assets/image/svg/badgeLogo.svg"),
      avatarPlaceholder: require("../assets/image/png/avatarPlaceholer.png"),
      locale: loadStore("besukha").auth.language,
    };
  },
  computed: mapState({
    name: (state) => state.auth.name,
    points: (state) => state.auth.points,
    avatar: (state) => state.auth.image,
  }),
  methods: {
    getFirstName() {
      let fullName = this.name;
      if (!fullName) return "";
      return fullName.trim().split(" ")[0];
    },
    getEmoticonsCount() {
      return this.points;
    },
    getAvatar() {
      if (this.avatar) {
        return `data:image/png;base64,${this.avatar}`;
      } else {
        return this.avatarPlaceholder;
      }
    },
    goToDashBoard() {
      this.$router.push("/dashboard");
    },
    goToProfile() {
      this.$router.push("/profile");
    },
  },
};
</script>

